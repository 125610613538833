import { useEffect } from "react";
import { ReactElement, ReactNode } from "react";

import { init } from "@amplitude/analytics-browser";
import { NextPage } from "next";
import { AppProps } from "next/app";
import { ErrorProps } from "next/error";
import { useRouter } from "next/router";
import Script from "next/script";

import { CssBaseline } from "@busbud/design-system-components";
import ThemeProvider from "@busbud/design-system-components/dist/styles/ThemeProvider";
import theme from "@busbud/design-system-components/dist/themes/busbud";

import { LayoutProps } from "@components/layout";
import { LocaleKey, localesToPubwebLocale } from "constants/i18n";

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type CustomAppProps = AppProps &
  LayoutProps & {
    forms: FormsFragment[];
    sharedFormLabels: SharedLabelsFragment;
    err?: ErrorProps;
    Component: NextPageWithLayout;
  };

const MyApp = ({ Component, pageProps, err }: CustomAppProps): JSX.Element => {
  const router = useRouter();

  useEffect(() => {
    init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY as string);
  }, []);

  useEffect(() => {
    if (window?.grecaptcha && process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA_CLIENT_KEY)
      window.grecaptcha.ready(async () => {
        await window.grecaptcha.execute(
          process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA_CLIENT_KEY as string,
          {
            action: "pageLoad",
          }
        );
      });
  }, [router.route]);

  const getLayout = Component.getLayout ?? ((page) => page);
  // locales for captcha match pubweb: https://developers.google.com/recaptcha/docs/language
  const localeForCaptcha = router.locale
    ? localesToPubwebLocale[router.locale as LocaleKey]
    : localesToPubwebLocale["en-us"];
  return (
    <>
      <ThemeProvider theme={theme}>
        <>
          <CssBaseline />
          {getLayout(<Component {...pageProps} err={err} />)}
        </>
      </ThemeProvider>
      <Script
        strategy="afterInteractive"
        src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA_CLIENT_KEY}&badge=bottomleft&hl=${localeForCaptcha}`}
      />
    </>
  );
};

export default MyApp;
