export const localesToStrapiLocale = {
  "en-us": "en",
  de: "de",
  es: "es",
  fr: "fr",
  it: "it",
  "pt-br": "pt-BR",
  ru: "ru",
  tr: "tr",
};

export const localesToPubwebLocale = {
  "en-us": "en",
  de: "de",
  es: "es",
  fr: "fr",
  it: "it",
  "pt-br": "pt",
  ru: "ru",
  tr: "tr",
};

export const localesToDayjsLocale: Record<LocaleKey, string> = {
  "en-us": "en",
  de: "de",
  es: "es",
  fr: "fr",
  it: "it",
  "pt-br": "pt-br",
  ru: "ru",
  tr: "tr",
};

export const localeLabel = {
  "en-us": "English (US)",
  de: "Deutsch",
  es: "Español",
  fr: "Français",
  it: "Italiano",
  "pt-br": "Português do Brasil",
  ru: "Русский",
  tr: "Türkçe",
};

export const defaultLocale = "en-us";

export type LocaleKey = keyof typeof localesToStrapiLocale;

export const locales = Object.keys(localesToStrapiLocale) as LocaleKey[];

export const getLocaleFromStrapiLocale = (
  strapiLocale: string
): LocaleKey | undefined => {
  return locales.find(
    (locale) =>
      localesToStrapiLocale[locale].toLowerCase() === strapiLocale.toLowerCase()
  );
};
